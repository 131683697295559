const baseUrl = "/api/parkingproducts";

type ParkingProductsApiType = {
  getParkingProductsUrl: (
    seasonTicketOwnerCrmId: string,
    location: string
  ) => string;
  getDelegateParkingRightsUrl: () => string;
  getUpdateDelegationUrl: () => string;
  getDelegatedParkingRightsUrl: (
    seasonTicketOwnerCrmId: string,
    language: string
  ) => string;
  getLocatedParkingProductsUrl: (
    seasonTicketOwnerCrmId: string,
    language: string
  ) => string;
  getDelegeeDetailsUrl: (registrationId: string, language: string) => string;
  getDeleteDelegeeUrl: (registrationId: string) => string;
  getResendDelegeeInviteUrl: (
    registrationId: string,
    seasonTicketOwnerCrmId: string
  ) => string;
  getRevokeParkingRightsUrl: () => string;
  getDelegeesUrl: (seasonTicketOwnerCrmId: string, language: string) => string;
  getDeleteDelegeesUrl: () => string;
  getValidateDelegeesUrl: (seasonTicketOwnerCrmId: string) => string;
  getDelegeesValidationResultUrl: (seasonTicketOwnerCrmId: string, fileReference: string, language: string) => string;
  getDelegeesInvitationResultUrl: (seasonTicketOwnerCrmId: string, fileReference: string, language: string) => string;
  getStartBulkInvitationsUrl: (seasonTicketOwnerCrmId: string, fileReference: string) => string;
  getBulkImportProgressUrl: (seasonTicketOwnerCrmId: string, fileReference: string) => string;
  getBulkImportStatusUrl: (seasonTicketOwnerCrmId: string) => string;
  getAssignVehicleUrl: (seasonTicketOwnerCrmId: string) => string;
  getUpdateVehicleAssignmentUrl: (seasonTicketOwnerCrmId: string, aggregateId: string) => string;
  getVehicleAssignmentsUrl: (req: GetVehicleAssignmentsQuery) => string;
  getVehicleAssignmentUrl: (aggregateId: string) => string;
  getRevokeVehicleAssignmentsUrl: () => string;
  getDeleteVehicleAssignmentsUrl: () => string;
  getVehicleAssignmentsMyFleetUrl: (req: GetVehicleAssignmentsQuery) => string;
};

const getParkingProductsUrl = (
  seasonTicketOwnerCrmId: string,
  location: string
) => `${baseUrl}/getparkingproducts/${seasonTicketOwnerCrmId}/${location}`;

const getDelegateParkingRightsUrl = () => `${baseUrl}/delegateparkingrights`;

const getUpdateDelegationUrl = () => `${baseUrl}/updatedelegation`;

const getDelegatedParkingRightsUrl = (
  seasonTicketOwnerCrmId: string,
  language: string
) =>
  `${baseUrl}/getdelegatedparkingrights/${seasonTicketOwnerCrmId}/${language}`;

const getLocatedParkingProductsUrl = (
  seasonTicketOwnerCrmId: string,
  language: string
) =>
  `${baseUrl}/getlocatedparkingproducts/${seasonTicketOwnerCrmId}/${language}`;

const getDelegeeDetailsUrl = (registrationId: string, language: string) =>
  `${baseUrl}/getdelegeedetails/${registrationId}/${language}`;

const getDeleteDelegeeUrl = (registrationId: string) =>
  `${baseUrl}/deletedelegee/${registrationId}`;

const getResendDelegeeInviteUrl = (
  registrationId: string,
  seasonTicketOwnerCrmId: string
) =>
  `${baseUrl}/ResendDelegeeInvitation/${registrationId}/${seasonTicketOwnerCrmId}`;

const getDelegeesUrl = (seasonTicketOwnerCrmId: string, language: string) =>
  `${baseUrl}/delegees/${seasonTicketOwnerCrmId}/${language}`;

const getRevokeParkingRightsUrl = () => `${baseUrl}/revokeparkingrights`;

const getDeleteDelegeesUrl = () => `${baseUrl}/deletedelegations`;

const getValidateDelegeesUrl = (seasonTicketOwnerCrmId: string) =>
    `${baseUrl}/ValidateDelegees/${seasonTicketOwnerCrmId}`;

const getDelegeesValidationResultUrl = (seasonTicketOwnerCrmId: string, fileReference: string, language: string) =>
    `${baseUrl}/BulkInvitations/${seasonTicketOwnerCrmId}/file-reference/${fileReference}/validationresults/${language}`;

const getDelegeesInvitationResultUrl = (seasonTicketOwnerCrmId: string, fileReference: string, language: string) =>
    `${baseUrl}/BulkInvitations/${seasonTicketOwnerCrmId}/file-reference/${fileReference}/invitationresults/${language}`;

const getStartBulkInvitationsUrl =  (seasonTicketOwnerCrmId: string, fileReference: string) =>
    `${baseUrl}/BulkInvitations/${seasonTicketOwnerCrmId}/file-reference/${fileReference}`;

const getBulkImportProgressUrl = (seasonTicketOwnerCrmId: string, fileReference: string) =>
    `${baseUrl}/BulkInvitations/${seasonTicketOwnerCrmId}/file-reference/${fileReference}/progress`;

const getBulkImportStatusUrl = (seasonTicketOwnerCrmId: string) =>
    `${baseUrl}/BulkInvitations/${seasonTicketOwnerCrmId}/status`;

const getAssignVehicleUrl = (seasonTicketOwnerCrmId: string) =>
  `${baseUrl}/VehicleAssignments/${seasonTicketOwnerCrmId}`;

const getUpdateVehicleAssignmentUrl = (seasonTicketOwnerCrmId: string, aggregateId: string) =>
  `${baseUrl}/VehicleAssignments/${seasonTicketOwnerCrmId}/${aggregateId}`;

const getVehicleAssignmentUrl = (aggregateId: string) =>
  `${baseUrl}/VehicleAssignments/${aggregateId}`;

const getRevokeVehicleAssignmentsUrl = () => `${baseUrl}/VehicleAssignments/revoke`;

const getDeleteVehicleAssignmentsUrl = () => `${baseUrl}/VehicleAssignments/delete`;

export type GetVehicleAssignmentsQuery = {
  seasonTicketOwnerCrmId: string;
  placeId: number;
  language: string;
  pmcIds?: number[];
  numberPlateValue?: string;
  page: number;
  pageSize: number;
  assignedDateStart?: string;
  assignedDateEnd?: string;
  sortBy?: 'description' | 'numberPlate' | 'assignedDate';
  ascending?: boolean;
  description?: string;
};

function getVehicleAssignmentsUrl(req: GetVehicleAssignmentsQuery) {
  const { seasonTicketOwnerCrmId, placeId, ...others } = req;
  const qs = getQueryString(others);

  return `${baseUrl}/VehicleAssignments/${seasonTicketOwnerCrmId}/${placeId}?${qs}`
}

function getVehicleAssignmentsMyFleetUrl(req: GetVehicleAssignmentsQuery) {
  const { seasonTicketOwnerCrmId, ...others } = req;

  const qs = getQueryString(others);
  return `${baseUrl}/VehicleAssignments/fleet/${req.seasonTicketOwnerCrmId}?${qs}`;

  
}

function getQueryString(others: any) {
  const params = new URLSearchParams();

  for (const [key, val] of Object.entries(others)) {
    if (Array.isArray(val)) {
      for (const x of val) {
        if (!x) continue;
        params.append(key, x.toString());
      }
    }
    else {
      if (!val) continue;
      params.append(key, val.toString());
    }
  }

  const qs = params.toString();
  return qs;
}

const mod: ParkingProductsApiType = {
  getParkingProductsUrl,
  getDelegateParkingRightsUrl,
  getUpdateDelegationUrl,
  getDelegatedParkingRightsUrl,
  getLocatedParkingProductsUrl,
  getDelegeeDetailsUrl,
  getDeleteDelegeeUrl,
  getResendDelegeeInviteUrl,
  getDelegeesUrl,
  getRevokeParkingRightsUrl,
  getDeleteDelegeesUrl,
  getValidateDelegeesUrl,
  getDelegeesValidationResultUrl,
  getDelegeesInvitationResultUrl,
  getStartBulkInvitationsUrl,
  getBulkImportProgressUrl,
  getBulkImportStatusUrl,
  getAssignVehicleUrl,
  getVehicleAssignmentsUrl,
  getUpdateVehicleAssignmentUrl,
  getVehicleAssignmentUrl,
  getVehicleAssignmentsMyFleetUrl,
  getRevokeVehicleAssignmentsUrl,
  getDeleteVehicleAssignmentsUrl
};

export default mod;
