import React, { createContext, useReducer } from "react";
import {
  DataTableContextState,
  useDataTableContext,
} from "../components/DataTable/DataTableContext/DataTableContextProvider";
import { myFleetContextTypes } from "./store/actions/myFleet.types";
import { MyFleetContextDataGridState } from "./store/reducers/myFleet.reducer";
import myFleetReducer, { initialState } from "./store/reducers/myFleet.reducer";


type MyFleetContextType = {
  dataTable: DataTableContextState;
  myFleetContextState: MyFleetContextDataGridState;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  setSorting: (sortBy: string) => void;
  resetPageNumber: () => void;
  resetPageSize: () => void;
};

export const MyFleetContext = createContext<MyFleetContextType>({} as MyFleetContextType);

interface IMyFleetContextProps {}

const MyFleetContextProvider: React.FC<IMyFleetContextProps> = ({ children }) => {
  const dataTableContext = useDataTableContext();
  const [ myFleetContextState, dispatch ] = useReducer(myFleetReducer, initialState);

  const setSorting = (sortBy: string) => {
    dispatch({
      type: myFleetContextTypes.SET_SORTING,
      payload: sortBy,
    });
  };

  const setPageNumber = (pageNumber: number): void => dataTableContext.setPageNumber(pageNumber);
  const setPageSize = (pageSize: number): void => dataTableContext.setPageSize(pageSize);
  const resetPageNumber = (): void => dataTableContext.resetPageNumber();
  const resetPageSize = (): void => dataTableContext.resetPageSize();

  const store: MyFleetContextType = {
    dataTable: dataTableContext.state,
    myFleetContextState,
    setSorting,
    setPageNumber,
    setPageSize,
    resetPageNumber,
    resetPageSize,
  };

  return <MyFleetContext.Provider value={store}>{children}</MyFleetContext.Provider>;
};

export default MyFleetContextProvider;
