import { useContext } from "react";
import { MyProductsContext } from "../MyProductsContextProvider";

const useMyProductsContext = () => {
    const myProductsContext = useContext(MyProductsContext);

    return myProductsContext;
};

export default useMyProductsContext;
