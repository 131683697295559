import React, { useEffect, useCallback, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import { IDataTableRow } from "../../../../components/DataTable/DataTableBody/DataTableBody";
import useAppContext from "../../../../context/hooks/useAppContext";
import { AssignedParkingRight } from "../../../../models/delegations/DelegatedParkingProductsModel";
import useMyProductsContext from "../../../../context/hooks/useMyProductsContext";
import useFleetManagerContext from "../../../../context/hooks/useFleetManagerContext";
import { ForFleetManager } from "../../../../models/ForFleetManager";
import { WithFiltersAndPaging } from "../../../../models/filters/WithFiltersAndPaging";
import {
  DataTableContextFilters,
  useDataTableContext,
} from "../../../../components/DataTable/DataTableContext/DataTableContextProvider";
import filteredColumnObjectBuilder from "../ObjectBuilders/FilteredColumnObjectBuilder";
import { FmpFilters } from "../../../../constants/filtering.constants";
import { FilterType } from "../../../../components/DataTable/DataTableFilters/DataTableFilters";
import {
  NumberPlate,
  ProductChip,
  useCurrentlySelectedParkingProduct,
  useGetOccupiedParkingRightsCount,
  useProductOptions,
  useSharedProductsMenuAction,
} from "./common";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { isNil } from "lodash";
import DataTable from "../../../../components/DataTable/DataTable";
import {
  DeleteDelegeeIcon,
  DeleteDelegeeMenuItem,
  EditIcon,
  NoPaddingGrid,
} from "../../FleetManagerDataTable/StyledComponents";
import { Column } from "../../../../components/DataTable/DataTableHead/DataTableHead";
import { MenuItem } from "@material-ui/core";
import parkingProductsActions from "../../../../store/actions/parkingProducts.actions";
import { VehicleAssignments } from "../../../../constants/vehicleAssignment.constants";

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export interface AssignedVehicleRow extends IDataTableRow {
  numberPlate: JSX.Element;
  description?: string;
  product: JSX.Element;
  assignedDate: string;
}
type VehicleAssignmentsTableProps = Readonly<{
  onRevokeClicked: () => void;
  onDeleteClicked: () => void;
  subHeading?: ReactNode;
}>

const _createData = (vehicleAssignment: AssignedParkingRight): AssignedVehicleRow => {
  return {
    rowKey: vehicleAssignment.vehicleAssignmentId + VehicleAssignments.KEY_SEPARATOR + vehicleAssignment.parkingRight.pmc,
    numberPlate: (
      <NumberPlate countryCode={vehicleAssignment.vehicle.countryCode} identifier={vehicleAssignment.vehicle.identifier} />
    ),
    description: vehicleAssignment.vehicle.description,
    product: (
      <Wrapper>
        <ProductChip
          name={vehicleAssignment.parkingRight.name}
          location={vehicleAssignment.parkingRight.location}
        />
      </Wrapper>
    ),
    assignedDate: moment(vehicleAssignment.assigned).format("DD/MM/YYYY"),
  };
};

type MenuItemsProps = Readonly<{
  onDelete: () => void;
  onEdit: () => void;
}>;

function MenuItems(props: MenuItemsProps) {
  const { t } = useTranslation("fleetManagerTable");

  return (
    <>
      <MenuItem key="edit" onClick={() => props.onEdit()}>
        <EditIcon />
        {t("contextMenuActions.editVehicle")}
      </MenuItem>
      <DeleteDelegeeMenuItem key="delete" onClick={() => props.onDelete()}>
        <DeleteDelegeeIcon />
        {t("contextMenuActions.deleteVehicle")}
      </DeleteDelegeeMenuItem>
    </>
  );
}

function VehiclesTable(props: VehicleAssignmentsTableProps) {
  const dispatch = useAppDispatch();
  const vehicleAssignments = useAppSelector((state) => state.parkingProducts.vehicles);
  const getVehicleAssignments = useCallback(
    (request: WithFiltersAndPaging<ForFleetManager>) => {
      dispatch(parkingProductsActions.getVehicles(request) as any);
    },
    [dispatch]
  );
  const { t } = useTranslation(["myProducts", "globals", "dataTable", "fleetManagerTable"]);

  const { myProductsState, setSorting } = useMyProductsContext();
  const {
    fleetManagerState,
    setSelectedParkingRightIds,
    toggleEditVehicleAssignment,
    toggleDeleteVehicleAssignmentsWarning
  } = useFleetManagerContext();

  const dataTableContext = useDataTableContext();

  const { appState } = useAppContext();

  const getTotalOccupiedParkingRights = useGetOccupiedParkingRightsCount();

  const selectedLocatedProduct = useCurrentlySelectedParkingProduct();

  const getFilters = (): DataTableContextFilters[] => [
    {
      key: FmpFilters.placeId,
      type: FilterType.QueryParam,
      value: myProductsState.selectedLocation.toString(),
    },
    {
      key: FmpFilters.sortBy,
      type: FilterType.QueryParam,
      value: myProductsState.dataTable.sortBy,
    },
    {
      key: FmpFilters.orderDesc,
      type: FilterType.QueryParam,
      value: myProductsState.dataTable.orderDesc?.toString(),
    },
    ...(dataTableContext.state.filters as DataTableContextFilters[]),
  ];

  const getRequestModel = (): WithFiltersAndPaging<ForFleetManager> => {
    const request: WithFiltersAndPaging<ForFleetManager> = {
      entity: {
        seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
        language: appState.selectedLanguage,
      },
      pagination: {
        pageSize: dataTableContext.state.pageSize!,
        pageNumber: dataTableContext.state.pageNumber!,
      },
      filters: getFilters(),
    };

    return request;
  };

  const fetchVehicleAssignments = () => {
    if (appState.user.seasonTicketOwnerCrmId) {
      getVehicleAssignments(getRequestModel());
    }
  };

  const memoizedFetch = useCallback(
    (): void => fetchVehicleAssignments(), // eslint-disable-next-line
    [
      dataTableContext.state.pageSize,
      dataTableContext.state.pageNumber,
      myProductsState.dataTable.orderDesc,
      myProductsState.dataTable.sortBy,
      myProductsState.selectedLocation,
      appState.user.seasonTicketOwnerCrmId,
      appState.selectedLanguage,
    ]
  );

  useEffect(() => {
    memoizedFetch();
  }, [memoizedFetch]);

  const productTableOptions = useProductOptions();
  const sharedProductActions = useSharedProductsMenuAction();

  const columns: Column<AssignedVehicleRow>[] = filteredColumnObjectBuilder.buildVehiclesColumns(
    t,
    productTableOptions
  );

  const onSort = (event: React.MouseEvent<unknown>, property: keyof AssignedVehicleRow) => {
    setSorting(property.toString());
  };

  const getTableRows = (): AssignedVehicleRow[] => {
    const { pages } = vehicleAssignments;
    if (isNil(pages)) return [];
    const pageData = pages[dataTableContext.state.pageNumber!]?.data;
    if (isNil(pageData)) return [];
    return pageData.map(_createData);
  };

  const isLoading =
    vehicleAssignments.pages?.[dataTableContext.state.pageNumber!]?.loading ?? false;
  const rows = getTableRows();

  const handleEdit = (row: AssignedVehicleRow | null) => {
    if (isNil(row)) {
      return;
    }
    toggleEditVehicleAssignment(row);
  };

  const handleDelete = (row: AssignedVehicleRow | null) => {
    if (isNil(row)) {      
      return;
    }    
    toggleDeleteVehicleAssignmentsWarning(row);
  };

  return (
    <NoPaddingGrid container item direction="row" xs={12}>
      <NoPaddingGrid container item direction="column" xs={12}>
        <DataTable
          rows={rows}
          columns={columns}
          key={myProductsState.selectedLocation}
          loading={isLoading}
          orderBy={myProductsState.dataTable.sortBy ?? ""}
          pages={vehicleAssignments.totalPages}
          onFilterClicked={fetchVehicleAssignments}
          onResetFiltersClicked={fetchVehicleAssignments}
          onRequestSort={onSort}
          translationOverrideKey={"vehicles"}
          selectedRowKeys={fleetManagerState.selectedRowKeys}
          setSelectedRowKeys={setSelectedParkingRightIds}
          renderActionMenu={(focusedRow, onClose) => (
            <MenuItems
              onDelete={() => handleDelete(focusedRow)}
              onEdit={() => handleEdit(focusedRow)}
            />
          )}
          order={myProductsState.dataTable.orderDesc ? "desc" : "asc"}
          heading={{
            options: {
              useHeading: true,
              showHeadingCount: true,
            },
            title: t("globals:parkingRights"),
            count: getTotalOccupiedParkingRights(selectedLocatedProduct || {}),
          }}
          filtering={{
            options: {
              useFilters: true,
            },
          }}
          actions={{
            options: {
              useActions: true,
            },
            revoke: {
              useRevoke: true,
              showRevoke: fleetManagerState.selectedRowKeys.length > 0,
              revokeLabel: `${t("globals:revoke")} (${fleetManagerState.selectedRowKeys.length})`,
              onRevokeClicked: props.onRevokeClicked,
            },
            menu: sharedProductActions.menu,
          }}
          subHeading={props.subHeading}
        />
      </NoPaddingGrid>
    </NoPaddingGrid>
  );
}

export default VehiclesTable;
