import { TFunction } from "i18next";
import * as Yup from "yup";
import { format } from "../../../utils";
import { useFormik } from "formik";
import { useMemo } from "react";

export interface IVehicleFormValues {
  description: string;
  numberPlate: string;
  numberPlatePrefix: string;
  products?: number[];
}

const defaultNumberPlatePrefix = "NL";

export function createYupSchema(t: TFunction, isEditMode: boolean) {
  const productValidationSchema = isEditMode
    ? Yup.array(Yup.number()).default([])
    : Yup.array(Yup.number())
        .min(1, format(t("errors:min.field"), t("globals:parkingRights"), 1))
        .required(format(t("errors:required.field"), t("globals:parkingRights").toLowerCase()))
        .default([]);

  return Yup.object({
    description: Yup.string()
      .max(100, t("errors:maxLength", { field: t("vehicleForm:description"), count: 100 }))
      .optional()
      .default(""),
    numberPlatePrefix: Yup.string()
      .required(
        format(
          t("errors:required.field"),
          t("visitors:visitorDetailsForm.numberPlateRegionPlaceholder").toLowerCase()
        )
      )
      .default(defaultNumberPlatePrefix),
    products: productValidationSchema,
  });
}

const initialValues: IVehicleFormValues = {
  numberPlate: "",
  numberPlatePrefix: defaultNumberPlatePrefix,
  description: "",
  products: [],
};

const useValidationSchema = (
  t: TFunction,
  isEditMode: boolean,
  onSubmit: (values: IVehicleFormValues) => void
) => {
  const validationSchema = useMemo(() => createYupSchema(t, isEditMode), [t, isEditMode]);

  const formik = useFormik<IVehicleFormValues>({
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues: initialValues,
  });

  return {
    validationSchema,
    formik,
  };
};

export default useValidationSchema;
