import { useContext } from "react";
import { MyFleetContext } from "../MyFleetContextProvider";

const useMyFleetContext = () => {
    const context = useContext(MyFleetContext);

    return context;
};

export default useMyFleetContext;
