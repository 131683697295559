import React, { useRef, Dispatch, useEffect, useMemo } from "react";
import { Formik, FormikProps } from "formik";
import { InputLabel, Grid, Container, Button, ButtonGroup } from "@material-ui/core";
import RequiredInputLabel from "../../ui/RequiredInputLabel/RequiredInputLabel";
import { Translation, useTranslation } from "react-i18next";
import { CloseOutlined } from "@material-ui/icons";
import { variables } from "../../theme/variables";
import { without, contains } from "underscore";
import Heading from "../../ui/Heading/Heading";
import CustomButton from "../../ui/CustomButton/CustomButton";
import CustomInput from "../../ui/CustomInput/CustomInput";
import ParkingRightsGrid from "./ParkingRightsGrid/ParkingRightsGrid";
import Spacer from "../../ui/Spacer/Spacer";
import { RootReducer } from "../../store/reducers";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import { connect } from "react-redux";
import { ParkingRightsRequestModel } from "../../models/ParkingRightsRequestModel";
import useValidationSchema, { IDelegeeFormValues } from "./hooks/useValidationSchema";
import useAppContext from "../../context/hooks/useAppContext";
import { checkHasNoCapacity, checkEmailExists } from "../../utils";
import CustomError from "../../ui/CustomError/CustomError";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import { DelegationType } from "../../context/store/reducers/fleetManagerContext.reducer";
import { NoPaddingBottomGrid } from "../DataTable/DataTableStyledComponents";
import "./_delegeeForm.scss";
import WarningColumn from "../../ui/CenteredWarning/CenteredWarning";
import { DeleteDelegeeIcon, DeleteText, DeleteWrapper } from "../DataTable/DataTableFilters/CommonStyledComponents";
import useImportDelegationsContext from "../../context/hooks/useImportDelegationsContext";

const { typography } = variables;
interface IFormProps extends StateProps, DispatchProps {
  onClose: () => void;
  refreshHandler: () => void;
  registrationId: string;
}

const DelegeeForm: React.FC<IFormProps> = (props) => {
  const {
    onClose,
    refreshHandler,
    parkingProducts,
    getParkingProducts,
    delegateParkingRights,
    updateDelegation,
    getDelegeeDetails,
    clearDelegeeDetails,
    resetBulkImportProgress,
    getBulkImportStatus,
    disposeError
  } = props;

  const { appState } = useAppContext();
  const { fleetManagerState, toggleDeleteDelegeeWarning, setDelegationType, setDragAndDropView } =
    useFleetManagerContext();

  const isEditMode = fleetManagerState.isEditDelegeeMode;

  const { t } = useTranslation(["delegeeForm", "globals", "errors"]);
  const { validationSchema, initialValues } = useValidationSchema(
    t,
    isEditMode,
    fleetManagerState.delegationType
  );

  const innerRef = useRef<FormikProps<IDelegeeFormValues>>(null);

  const fetchParkingProducts = () => {
    getParkingProducts(appState.user.seasonTicketOwnerCrmId as string, appState.selectedLanguage);
  };

  const fetchDelegeeDetails = () => {
    getDelegeeDetails(fleetManagerState.selectedRegistrationId, appState.selectedLanguage);
  };

  const fetchBulkImportStatus = () => {
    getBulkImportStatus(appState.user.seasonTicketOwnerCrmId as string);
  }

  const initForm = () => {
    fetchParkingProducts();
    fetchBulkImportStatus();

    if (isEditMode) {
      fetchDelegeeDetails();
    }
  };

  const resetDelegeeForm = () => {
    disposeError();
    clearDelegeeDetails();
    resetBulkImportProgress();
  };

  useEffect(() => {
    initForm();

    return () => resetDelegeeForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFormFields();
    // eslint-disable-next-line
  }, [parkingProducts.delegeeDetails.data]);

  useEffect(() => {
    if (hasNoCapacity()) {
      fetchParkingProducts();
      handleDeselectAll();
    }
    // eslint-disable-next-line
  }, [parkingProducts.error?.errors]);

  const setFormFields = () => {
    innerRef.current?.setFieldValue("name", parkingProducts.delegeeDetails.data?.name ?? "");
    innerRef.current?.setFieldValue(
      "email",
      parkingProducts.delegeeDetails.data?.emailAddress ?? ""
    );

    const rightsIds =
      parkingProducts.delegeeDetails.data != null
        ? parkingProducts.delegeeDetails.data.products?.map((product) => product.pmcId)
        : [];

    innerRef.current?.setFieldValue("rights", rightsIds);
  };

  const handleRightChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    parkingRights: number[]
  ) => {
    const { value } = event.target;
    const rightId = parseInt(value);

    let rights = [...parkingRights];
    if (contains(rights, rightId)) {
      rights = without(rights, rightId);
    } else {
      rights.push(rightId);
    }

    innerRef.current?.setFieldValue("rights", rights);

    disposeError();
  };

  const handleSelectAll = (parkingRights: number[]) => {
    innerRef.current?.setFieldValue("rights", parkingRights);
  };

  const handleDeselectAll = () => {
    innerRef.current?.setFieldValue("rights", []);
  };

  const submitCallback = () => {
    refreshHandler();
    onClose();
  };

  const handleFormSubmit = (values: IDelegeeFormValues) => {
    if (fleetManagerState.delegationType === DelegationType.Multiple) {
      goToUploadFile();
      return;
    }
    const model: ParkingRightsRequestModel = {
      seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
      email: values.email,
      name: values.name,
      pmcIds: values.rights ?? [],
    };

    if (isEditMode) {
      model.registrationId = parkingProducts.delegeeDetails.data?.registrationId as string;

      updateDelegation(model, submitCallback);
      return;
    }

    delegateParkingRights(model, submitCallback);    
  };

  const handleEmailChange = (event: React.ChangeEvent<any>): void => {
    const { value } = event.target;

    innerRef.current?.setFieldValue("email", value);

    disposeError();
  };

  const submitButtonText = () => {
    if (fleetManagerState.delegationType === DelegationType.Multiple) {
      return t("goToUpload");
    }

    return !isEditMode ? t("sendInvite") : t("updateDelegee");
  };

  const getFormTitle = () => (isEditMode ? t("editExistingDelegee") : t("addNewDelegee"));

  const delegeeEmailExists = (): boolean => checkEmailExists(parkingProducts.error);

  const hasNoCapacity = (): boolean => checkHasNoCapacity(parkingProducts.error);

  const delegateSingle = (): void => setDelegationType(DelegationType.Single);
  const delegateMultiple = (): void => setDelegationType(DelegationType.Multiple);
  const goToUploadFile = (): void => setDragAndDropView(true);

  const initiallySelectedPmcIds = useMemo(() => parkingProducts.delegeeDetails.data?.products.map(p => p.pmcId), [parkingProducts.delegeeDetails.data?.products]);

  const { setCheckParkingRights } = useImportDelegationsContext();

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      innerRef={innerRef}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <div className="form-container">
          <div className="form-header">
            <Container className="form-header-content">
              {isEditMode && (
                <DeleteWrapper onClick={toggleDeleteDelegeeWarning}>
                  <DeleteDelegeeIcon />
                  <DeleteText>{t("deleteDelegee")}</DeleteText>
                </DeleteWrapper>
              )}
              <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                {getFormTitle()}
              </Heading>
              <Button onClick={onClose} classes={{ root: "close-icon-btn" }} disableRipple>
                <CloseOutlined classes={{ root: "close-icon" }} />
              </Button>
            </Container>
          </div>
          <form noValidate className="delegee-form">
            {!isEditMode && (
              <Container>
                <NoPaddingBottomGrid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <NoPaddingBottomGrid container item direction="column" xs={12}>
                    <div className="delegation-type-wrapper">
                      <div className="delegation-type-selector">
                        <ButtonGroup variant="contained" fullWidth disableRipple>
                          <Button
                            onClick={delegateSingle}
                            color={
                              fleetManagerState.delegationType === DelegationType.Single
                                ? "primary"
                                : "default"
                            }
                            disableElevation
                          >
                            {t("single")}
                          </Button>
                          <Button
                            onClick={delegateMultiple}
                            color={
                              fleetManagerState.delegationType === DelegationType.Multiple
                                ? "primary"
                                : "default"
                            }
                            disableElevation
                          >
                            {t("multiple")}
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </NoPaddingBottomGrid>
                </NoPaddingBottomGrid>
              </Container>
            )}
            <div className="form-body">
              <Container>
                {fleetManagerState.delegationType == DelegationType.Single && (
                  <React.Fragment>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Heading fontSize={typography.fontSizeBase}>
                        <strong>{t("delegeeInformation")}</strong>
                      </Heading>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Grid container direction="column" xs={3} item>
                        <InputLabel htmlFor="name">{t("globals:name")}</InputLabel>
                      </Grid>
                      <Grid container direction="column" xs={9} item>
                        <CustomInput
                          id="name"
                          name="name"
                          autoComplete="off"
                          value={values.name}
                          onChange={handleChange}
                          classes={{ root: "form-input" }}
                          variant="outlined"
                          placeholder={t("namePlaceholder")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Grid container direction="column" xs={3} item>
                        <RequiredInputLabel fontSize={typography.fontSizeSmaller} htmlFor="email">
                          {t("globals:email")}
                        </RequiredInputLabel>
                      </Grid>
                      <Grid container direction="column" xs={9} item>
                        <CustomInput.Required
                          error={Boolean(errors.email) || delegeeEmailExists()}
                          id="email"
                          name="email"
                          autoComplete="off"
                          value={values.email}
                          onChange={handleEmailChange}
                          classes={{ root: "form-input" }}
                          variant="outlined"
                          placeholder={t("emailPlaceholder")}
                          helperText={
                            delegeeEmailExists() ? t("errors:emailExists") : errors.email
                          }
                          disabled={isEditMode}
                        />
                      </Grid>
                    </Grid>
                    <Spacer />
                  </React.Fragment>
                )}
                <Translation ns="delegeeForm">{(t) => (
                  <ParkingRightsGrid
                    data={parkingProducts.parkingProducts.data!}
                    initiallySelectedPmcIds={initiallySelectedPmcIds}
                    loading={parkingProducts.parkingProducts.loading}
                    onStateChange={(parkingRights) => setCheckParkingRights(parkingRights.filter(x => x.checked))}
                    onChange={(event) => handleRightChange(event, values.rights ?? [])}
                    onSelectAll={(ids) => handleSelectAll(ids)}
                    onDeselectAll={handleDeselectAll}
                    error={{
                      hasError: Boolean(errors.rights),
                      message: errors.rights,
                    }} 
                    title={t("delegateRights")}
                    selectAllTooltipText={t("selectTooltip")}
                    deselectAllTooltipText={t("deselectTooltip")}
                    selectAllText={t("selectAll")}
                    deselectAllText={t("deselectAll")}                />
                )}</Translation>

                {fleetManagerState.delegationType == DelegationType.Multiple && !parkingProducts.isBulkImportCompleted && (
                  <div className="bulkimport-inprogress-warning">
                    <WarningColumn
                      title={t("bulkImportInProgressWarningTitle")}
                      details={t("bulkImportInProgressWarningDescription")}
                      orangeWarning={true}
                    />
                  </div>
                )}

              </Container>
            </div>
          </form>
          <CustomError show={hasNoCapacity()} message={t("errors:noCapacity")} />
          <div className="form-footer">
            <Container className="form-footer-content">
              <React.Fragment>
                <CustomButton.Light onClick={onClose} text={t("globals:close")} id="close-invite" />
                <CustomButton.Primary
                  loading={parkingProducts.loading}
                  onClick={() => handleSubmit()}
                  text={submitButtonText()}
                  id="submit-invite"
                  disabled={fleetManagerState.delegationType == DelegationType.Multiple && !parkingProducts.isBulkImportCompleted}
                />
              </React.Fragment>
            </Container>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts } = state;

  return {
    parkingProducts
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getParkingProducts: (seasonTicketOwnerCrmId: string, location: string) =>
    dispatch(parkingProductsActions.getParkingProducts(seasonTicketOwnerCrmId, location)),
  delegateParkingRights: (parkingRights: ParkingRightsRequestModel, callback?: () => void) =>
    dispatch(parkingProductsActions.delegateParkingRights(parkingRights, callback)),
  updateDelegation: (parkingRights: ParkingRightsRequestModel, callback?: () => void) =>
    dispatch(parkingProductsActions.updateDelegation(parkingRights, callback)),
  disposeError: () => dispatch(parkingProductsActions.disposeError()),
  getDelegeeDetails: (registrationId: string, language: string) =>
    dispatch(parkingProductsActions.getDelegeeDetails(registrationId, language)),
  clearDelegeeDetails: () => dispatch(parkingProductsActions.clearDelegeeDetails()),
  resetBulkImportProgress: () => dispatch(parkingProductsActions.resetBulkImportProgress()),
  getBulkImportStatus: (seasonTicketOwnerCrmId: string) => dispatch(parkingProductsActions.getBulkImportStatus(seasonTicketOwnerCrmId))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DelegeeForm);
