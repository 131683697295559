import { myProductsContextTypes } from "../actions/myProducts.types";

export type MyProductsContextDataGridState = {
  delegeeNameFilter?: string;
  delegeeEmailFilter?: string;
  statusFilter?: number[];
  sortBy?: string;
  orderDesc?: boolean;
};

export type MyProductsContextState = {
  selectedLocation: number;
  dataTable: MyProductsContextDataGridState;
};

export type MyProductsContextAction = {
  type: string;
  payload?: number | string | string[] | number[];
};

export const initialState: MyProductsContextState = {
  selectedLocation: 0,
  dataTable: {
    orderDesc: true,
    sortBy: "invitationDate",
  },
};

export default (
  state: MyProductsContextState = initialState,
  action: MyProductsContextAction
): MyProductsContextState => {
  switch (action.type) {
    case myProductsContextTypes.SET_SELECTED_LOCATION:
      if (state.selectedLocation === action.payload) {
        return {
          ...state,
          selectedLocation: 0,
          dataTable: initialState.dataTable,
        };
      }

      return {
        ...state,
        selectedLocation: action.payload as number,
        dataTable: initialState.dataTable,
      };

    case myProductsContextTypes.SET_DELEGEE_NAME_FILTER:
      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          delegeeNameFilter: action.payload as string,
        },
      };

    case myProductsContextTypes.SET_DELEGEE_EMAIL_FILTER:
      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          delegeeEmailFilter: action.payload as string,
        },
      };

    case myProductsContextTypes.SET_STATUS_FILTER:
      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          statusFilter: action.payload as number[],
        },
      };

    case myProductsContextTypes.SET_SORTING:
      return {
        ...state,
        dataTable: {
          ...state.dataTable,
          sortBy: action.payload as string,
          orderDesc:
            state.dataTable.orderDesc != null
              ? !state.dataTable.orderDesc
              : false,
        },
      };

    case myProductsContextTypes.RESET_MYPRODUCTS_CONTEXT:
      return initialState;
    default:
      return state;
  }
};
