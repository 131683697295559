import { myFleetContextTypes } from "../actions/myFleet.types";

export type MyFleetContextDataGridState = {
  sortBy?: string;
  orderDesc?: boolean;
};

export type MyFleetContextAction = {
  type: string;
  payload?: string;
};

export const initialState: MyFleetContextDataGridState = {
  sortBy: "invitationDate",
  orderDesc: true,
};

export default (
  state: MyFleetContextDataGridState = initialState,
  action: MyFleetContextAction
): MyFleetContextDataGridState => {
  switch (action.type) {
    case myFleetContextTypes.SET_SORTING:
      return {
        ...state,
        sortBy: action.payload,
        orderDesc: state.orderDesc != null ? !state.orderDesc : false,
      };

    default:
      return state;
  }
};
