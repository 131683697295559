import React, { createContext, useReducer } from "react";
import { MyProductsContextState } from "./store/reducers/myProducts.reducer";
import myProductsContextReducer, { initialState } from "./store/reducers/myProducts.reducer";
import { myProductsContextTypes } from "./store/actions/myProducts.types";
import {
  useDataTableContext,
  DataTableContextState,
  DataTableContextFilters,
} from "../components/DataTable/DataTableContext/DataTableContextProvider";

export type MyProductsContextType = {
  myProductsState: MyProductsContextState;
  dataTable: DataTableContextState;
  setSelectedLocation: (placeId: number) => void;
  setDelegeeNameFilter: (delegeeName: string) => void;
  setDelegeeEmailFilter: (delegeeEmail: string) => void;
  setStatusFilter: (statuses?: number[]) => void;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  resetPageNumber: () => void;
  getFilters: () => DataTableContextFilters[];
  resetPageSize: () => void;
  setSorting: (sortBy: string) => void;
  resetContext: () => void;
};

export const MyProductsContext = createContext<MyProductsContextType>({} as MyProductsContextType);

interface IMyProductsContextProps {}

const MyProductsContextProvider: React.FC<IMyProductsContextProps> = ({ children }) => {
  const [myProductsState, dispatch] = useReducer(myProductsContextReducer, initialState);
  const dataTableContext = useDataTableContext();

  const setSelectedLocation = (placeId: number) => {
    dispatch({
      type: myProductsContextTypes.SET_SELECTED_LOCATION,
      payload: placeId,
    });
  };

  const setDelegeeNameFilter = (delegeeName?: string) => {
    dispatch({
      type: myProductsContextTypes.SET_DELEGEE_NAME_FILTER,
      payload: delegeeName,
    });
  };

  const setDelegeeEmailFilter = (delegeeName?: string) => {
    dispatch({
      type: myProductsContextTypes.SET_DELEGEE_EMAIL_FILTER,
      payload: delegeeName,
    });
  };

  const setStatusFilter = (statuses?: number[]) => {
    dispatch({
      type: myProductsContextTypes.SET_STATUS_FILTER,
      payload: statuses,
    });
  };

  const setSorting = (sortBy: string) => {
    dispatch({
      type: myProductsContextTypes.SET_SORTING,
      payload: sortBy,
    });
  };

  const resetContext = () =>
    dispatch({
      type: myProductsContextTypes.RESET_MYPRODUCTS_CONTEXT,
    });

  const setPageNumber = (pageNumber: number) => dataTableContext.setPageNumber(pageNumber);
  const setPageSize = (pageSize: number) => dataTableContext.setPageSize(pageSize);
  const resetPageNumber = (): void => dataTableContext.resetPageNumber();
  const resetPageSize = (): void => dataTableContext.resetPageSize();
  const getFilters = (): DataTableContextFilters[] =>
    dataTableContext.state.filters as DataTableContextFilters[];

  const store: MyProductsContextType = {
    myProductsState,
    dataTable: dataTableContext.state,
    setSelectedLocation,
    setDelegeeNameFilter,
    setDelegeeEmailFilter,
    setStatusFilter,
    resetContext,
    setPageNumber,
    resetPageNumber,
    resetPageSize,
    setPageSize,
    getFilters,
    setSorting,
  };

  return <MyProductsContext.Provider value={store}>{children}</MyProductsContext.Provider>;
};

export default MyProductsContextProvider;
