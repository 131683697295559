export const myProductsContextTypes = {
  SET_PAGE_NUMBER: "context/SET_PAGE_NUMBER",
  SET_PAGE_SIZE: "context/SET_PAGE_SIZE",
  SET_SELECTED_LOCATION: "context/SET_SELECTED_LOCATION",
  TOGGLE_SHOW_LOCATIONS_LIST: "context/TOGGLE_SHOW_LOCATIONS_LIST",
  SET_DELEGEE_NAME_FILTER: "context/SET_DELEGEE_NAME_FILTER",
  SET_DELEGEE_EMAIL_FILTER: "context/SET_DELEGEE_EMAIL_FILTER",
  SET_STATUS_FILTER: "context/SET_STATUS_FILTER",
  SET_SORTING: "context/SET_SORTING",
  RESET_MYPRODUCTS_CONTEXT: "context/RESET_MYPRODUCTS_CONTEXT",
};
