import React, { Dispatch, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Section from "../../../ui/Section/Section";
import DelegeeForm from "../../../components/DelegeeForm/DelegeeForm";
import { useTranslation } from "react-i18next";
import ActionDrawer from "../../../ui/ActionDrawer/ActionDrawer";
import { Helmet } from "react-helmet";
import DeleteDelegeeWarning from "../../../components/Warnings/DeleteDelegeeWarning";
import useFleetManagerContext from "../../../context/hooks/useFleetManagerContext";
import useMyFleetContext from "../../../context/hooks/useMyFleetContext";
import DelegeesDataTable from "./DelegeesDataTable/DelegeesDataTable";
import RevokeParkingRightsWarning from "../../../components/Warnings/RevokeParkingRightsWarning";
import useAppContext from "../../../context/hooks/useAppContext";
import { connect } from "react-redux";
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";
import { RootReducer } from "../../../store/reducers";
import { getMessage, getSelectedParkingRightIds } from "./MyFleetHelpers";
import BatchDeleteDelegeeWarning from "../../../components/Warnings/BatchDeleteDelegationWarning";
import { WithFiltersAndPaging } from "../../../models/filters/WithFiltersAndPaging";
import { ForFleetManager } from "../../../models/ForFleetManager";
import MultipleDelegeesForm from "../../../components/MultipleDelegeesForm/MultipleDelegeesForm";
import { useOnBulkImportFinishedCallback } from "../../../hooks/useOnBulkImportFinishedCallback";
import VehicleForm from "../../../components/VehicleForm/VehicleForm";
import TableSwitcher, { TabOption } from "../MyProducts/ParkingRightsDataTable/TableSwitcher";
import { useAppSelector } from "../../../store";
import { selectUseSingleDirectVehicleAssignment } from "../MyProducts/ParkingRightsDataTable/common";
import { useDataTableContext } from "../../../components/DataTable/DataTableContext/DataTableContextProvider";
import VehiclesTableMyFleet from "./VehiclesTableMyFleet/VehiclesTableMyFleet";
import DeleteVehiclesAssignmentWarning from "../../../components/Warnings/DeleteVehiclesAssignmentWarning";
import RevokeVehicleAssignmentWarning from "../../../components/Warnings/RevokeVehicleAssignmentWarning";

interface IProps extends StateProps, DispatchProps {}

const MyFleet = ({ 
  getDelegees, 
  getParkingProducts, 
  getVehicleAssignmentsMyFleet,
  delegees }: IProps) => {
  const { appState } = useAppContext();

  const {
    fleetManagerState,
    toggleAddDelegee,
    toggleAssignVehicle,
    toggleRevokeParkingRightsWarning,
    toggleEditDelegee,
    toggleEditVehicleAssignment,
    toggleDeleteDelegeeWarning,
    toggleRevokeVehicleAssignmentsWarning,
    toggleDeleteVehicleAssignmentsWarning,
    toggleBatchDeleteDelegationsWarning,
    setSelectedParkingRightIds,
    resetContext: resetFleetManagerContext,    
  } = useFleetManagerContext();
  
  const [tab, setTab] = useState(TabOption.FleetMembers);
  const {
     dataTable,
     resetPageNumber,
     resetPageSize,
     setSorting
  } = useMyFleetContext();
  
  const { resetContext, resetFilters } = useDataTableContext();
  const useSingleDirectVehicleAssignment = useAppSelector(selectUseSingleDirectVehicleAssignment);

  const { t } = useTranslation(["myFleet", "globals", "dataTable"]);
  const getMetaTitle = () =>
    fleetManagerState.showDelegeeDetails ? t("addDelegee") : t("myFleet");

  const fetchParkingProducts = () => {
    getParkingProducts(appState.user.seasonTicketOwnerCrmId as string, appState.selectedLanguage);
  };

  useEffect(() => {
    fetchParkingProducts();
    getVehicleAssignmentsMyFleet({      
      entity: {
        seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
        language: appState.selectedLanguage
      },
      pagination: {
        pageNumber:1,
        pageSize:1
      }
    });
    return () => {
      resetFleetManagerContext();
    };
    // eslint-disable-next-line
  }, []);

  const onDelegeeFormClose = () => {
    if (fleetManagerState.isEditDelegeeMode) {
      toggleEditDelegee("");
    } else {
      toggleAddDelegee();
    }
  };

  const onVehicleFormClose = () => {
    if (fleetManagerState.isEditVehicleAssignmentMode) {
      toggleEditVehicleAssignment();
    } else {
      toggleAssignVehicle();
    }
  };

  const onRefresh = () => {
    var request: WithFiltersAndPaging<ForFleetManager> = {
      entity: {
        seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
        language: appState.selectedLanguage,
      },
      pagination: {
        pageSize: dataTable.pageSize!,
        pageNumber: dataTable.pageNumber!,
      },
      filters: dataTable.filters,
    };

    getDelegees(request);
  };

  useOnBulkImportFinishedCallback(onRefresh);

  const getDeleteDelegeeWarningText = (parkingRightsCount: number): string =>
    parkingRightsCount > 0
      ? t("deleteDelegeeWarning.warning", { count: parkingRightsCount })
      : t("deleteDelegeeWarning.warningNoPRs");

  const getDeleteDelegeeWarningDetails = (parkingRightsCount: number): string =>
    parkingRightsCount > 0
      ? t("deleteDelegeeWarning.details", { count: parkingRightsCount })
      : t("deleteDelegeeWarning.detailsNoPRs");

  const getBatchDeleteWarning = (): string =>
    getMessage(
      t,
      "deleteDelegeeWarning.warningBatch",
      fleetManagerState.selectedRowKeys.length,
      "deleteDelegeeWarning.warningBatchPlural"
    );

  const getBatchDeleteDescription = (): string =>
    getMessage(
      t,
      "deleteDelegeeWarning.detailsBatch",
      fleetManagerState.selectedRowKeys.length,
      "deleteDelegeeWarning.detailsBatchPlural"
    );

  const selectedParkingRightIds = getSelectedParkingRightIds(
    fleetManagerState,
    dataTable,
    delegees
  );

  const onAddClickHandler = () => {
    setSelectedParkingRightIds([]);
    toggleAddDelegee();
  };

  const onAssignVehicleClickHandler = () => {
    toggleAssignVehicle();
  };

  const vehicleAssignmentsCount = useAppSelector((state) => state.parkingProducts.vehicleAssignmentsMyFleet.totalRecords);
  const fleetMembersCount = useAppSelector((state) => state.parkingProducts.delegees.totalRecords);
  const switcher = !useSingleDirectVehicleAssignment ? (
    <></>
  ) : (
    <TableSwitcher
      tab={tab}
      onChange={(newTab) => {
        if (tab !== newTab) {
          resetPageNumber();
          resetPageSize();
          setSelectedParkingRightIds([]);

          let sorting = "";
          switch (newTab) {
            case TabOption.FleetMembers:
              sorting = "invitationDate";
              break;
            case TabOption.Vehicles:
              sorting = "assignedDate";
              break;
          }

          resetContext();
          setSorting(sorting);
        }
        setTab(newTab);
      }}
      getCount={(option) => {
        switch (option) {
          case TabOption.FleetMembers:
            return fleetMembersCount ?? 0;
          case TabOption.Vehicles:
            return vehicleAssignmentsCount ?? 0
        }
      }}
    />
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Q-Park | ${getMetaTitle()}`}</title>
      </Helmet>
      <ActionDrawer
        open={fleetManagerState.showDelegeeDetails}
        onClose={onDelegeeFormClose}
        className="delegee-form-drawer"
      >
        {!fleetManagerState.dragAndDropView ? (
          <DelegeeForm
            onClose={onDelegeeFormClose}
            refreshHandler={onRefresh}
            registrationId={fleetManagerState.selectedRegistrationId}
          />
        ) : (
          <MultipleDelegeesForm onClose={onDelegeeFormClose} />
        )}
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showDeleteDelegeeWarning}
        onClose={toggleDeleteDelegeeWarning}
      >
        <DeleteDelegeeWarning
          getWarningText={getDeleteDelegeeWarningText}
          getWarningDetails={getDeleteDelegeeWarningDetails}
          onRefresh={onRefresh}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showRevokeParkingRightsWarning}
        onClose={toggleRevokeParkingRightsWarning}
      >
        <RevokeParkingRightsWarning
          onRefresh={onRefresh}
          selectedParkingRightIds={selectedParkingRightIds}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showRevokeParkingVehicleAssignment}
        onClose={toggleRevokeVehicleAssignmentsWarning}
      >
        <RevokeVehicleAssignmentWarning
          onRefresh={onRefresh}
          selectedVehicleAssignmentKeys={fleetManagerState.selectedRowKeys}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showDeleteVehiclesAssignment}
        onClose={toggleDeleteVehicleAssignmentsWarning}
      >
        <DeleteVehiclesAssignmentWarning
          onRefresh={onRefresh}
          selectedVehicleAssignmentKeys={fleetManagerState.selectedRowKeys}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showBatchDeleteDelegationsWarning}
        onClose={toggleBatchDeleteDelegationsWarning}
      >
        <BatchDeleteDelegeeWarning
          options={{
            warning: getBatchDeleteWarning(),
            warningDescription: getBatchDeleteDescription(),
          }}
          onRefresh={onRefresh}
        />
      </ActionDrawer>
      <ActionDrawer open={fleetManagerState.showAssignVehicle} onClose={onVehicleFormClose}>
        <VehicleForm onClose={onVehicleFormClose} />
      </ActionDrawer>

      <Grid container direction="row" alignItems="center">
        <Section>          
            {useSingleDirectVehicleAssignment && tab === TabOption.FleetMembers &&
              <DelegeesDataTable
                onAssignVehicleClicked={onAssignVehicleClickHandler}
                onInviteFleetMemberClicked={onAddClickHandler}
                onRevokeClicked={toggleRevokeParkingRightsWarning}
                onDeleteClicked={toggleBatchDeleteDelegationsWarning}
                subHeading={switcher} />}
            {useSingleDirectVehicleAssignment && tab === TabOption.Vehicles &&
              <VehiclesTableMyFleet
                onRevokeClicked={toggleRevokeVehicleAssignmentsWarning}
                onDeleteClicked={toggleDeleteVehicleAssignmentsWarning}
                onAssignVehicleClicked={onAssignVehicleClickHandler}
                onInviteFleetMemberClicked={onAddClickHandler}
                subHeading={switcher} />}
            {!useSingleDirectVehicleAssignment && 
              <DelegeesDataTable
                onAssignVehicleClicked={onAssignVehicleClickHandler}
                onInviteFleetMemberClicked={onAddClickHandler}
                onRevokeClicked={toggleRevokeParkingRightsWarning}
                onDeleteClicked={toggleBatchDeleteDelegationsWarning}/>}                     
        </Section>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { delegees } = state.parkingProducts;

  return { delegees };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getDelegees: (request: WithFiltersAndPaging<ForFleetManager>) =>
    dispatch(parkingProductsActions.getDelegees(request)),
  getParkingProducts: (seasonTicketOwnerCrmId: string, location: string) =>
    dispatch(parkingProductsActions.getParkingProducts(seasonTicketOwnerCrmId, location)),
  getVehicleAssignmentsMyFleet: (request:  WithFiltersAndPaging<ForFleetManager>) => 
    dispatch(parkingProductsActions.getVehicleAssignmentsMyFleet(request) as any), 
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyFleet);
