import { isNil } from "lodash";
import { GetVehicleAssignmentsQuery } from "../../api/parkingProducts.api";
import { AssignedVehicleSuccessfulMutation } from "../../models/vehicle-assignments/AssignedVehicleSuccessfulMutation";
import { AssignVehicleRequestModel } from "../../models/vehicle-assignments/AssignVehicleRequestModel";
import { AssignedParkingRight } from "../../models/delegations/DelegatedParkingProductsModel";
import { LocatedParkingProduct } from "../../models/LocatedParkingProduct";
import { UpdateVehicleRequestModel } from "../../models/vehicle-assignments/UpdateVehicleRequestModel";
import { ParkingProductState, VehiclesState } from "../reducers/parkingProducts.reducer";
import { isEmpty } from "underscore";

function reduceUpdate(
  state: ParkingProductState,
  aggregateId: string,
  update: UpdateVehicleRequestModel
): ParkingProductState {
  const revokedPmcIds = state
    .selectedVehicleAssignment.data!.assignedProducts.map((x) => x.pmcId)
    .filter((assignedPmc) => !update.pmcIds.includes(assignedPmc));
  const assignedPmcIds = update.pmcIds.filter(
    (pmcId) =>
      !state.selectedVehicleAssignment.data!.assignedProducts.some(
        (assignedProduct) => assignedProduct.pmcId === pmcId
      )
  );

  const newLocatedParkingProducts = incrementTotalAssignedCount(
    state.locatedParkingProducts.data!,
    Object.fromEntries([
      ...revokedPmcIds.map((revokedPmc) => [revokedPmc, -1]),
      ...assignedPmcIds.map((assignedPmcId) => [assignedPmcId, 1]),
    ])
  );

  const newVehicleState = reduceVehicleState(
    state.vehicles,
    state.locatedParkingProducts.data!,
    aggregateId,
    update
  );

  return {
    ...state,
    locatedParkingProducts: {
      ...state.locatedParkingProducts,
      data: newLocatedParkingProducts,
    },
    vehicles: newVehicleState
  };
}

function incrementTotalAssignedCount(
  locations: LocatedParkingProduct[],
  pmcIdToAmount: { [pmcId: number]: number }
): LocatedParkingProduct[] {
  return locations.map((location): LocatedParkingProduct => {
    const newParkingProducts = location.parkingProducts.map((parkingProduct) => {
      const amount = pmcIdToAmount[parkingProduct.pmcId!];
      if (amount) {
        return {
          ...parkingProduct,
          totalAssignedParkingRights: parkingProduct.totalAssignedParkingRights! + amount,
        };
      }
      return parkingProduct;
    });

    const totalAssignedParkingRights = newParkingProducts.reduce(
      (acc, x) => acc + x.totalAssignedParkingRights!,
      0
    );

    return {
      ...location,
      totalAssignedParkingRights,
      parkingProducts: newParkingProducts,
    };
  });
}

function rowMatchesQuery(
  assignedProduct: AssignedParkingRight,
  query: GetVehicleAssignmentsQuery,
  getPlaceId: (pmc: number) => number
): boolean {
  return (
    query.placeId === getPlaceId(assignedProduct.parkingRight.pmc) &&
    (isNil(query.description) || isEmpty(query.description) ||
      (!isNil(assignedProduct.vehicle.description) &&
        assignedProduct.vehicle.description.toLowerCase().includes(query.description.toLowerCase()))) &&
    (isNil(query.numberPlateValue) || isEmpty(query.numberPlateValue) ||
      assignedProduct.vehicle.identifier.includes(query.numberPlateValue.toUpperCase())) &&
    (isNil(query.pmcIds) || isEmpty(query.pmcIds) || query.pmcIds.includes(assignedProduct.parkingRight.pmc)) &&
    (isNil(query.assignedDateStart) ||
      new Date(query.assignedDateStart).getTime() <= assignedProduct.assigned.getTime()) &&
    (isNil(query.assignedDateEnd) ||
      assignedProduct.assigned.getTime() <= new Date(query.assignedDateEnd).getTime())
  );
}

function reduceVehicleState(
  vehicles: VehiclesState,
  locatedParkingProducts: LocatedParkingProduct[],
  aggregateId: string,
  model: UpdateVehicleRequestModel
): VehiclesState {
  const fakeRows: AssignedParkingRight[] = model.pmcIds.map((pmcId) => {
    const { name, location } = getProductInfo(locatedParkingProducts, pmcId);

    return createFakeRow(aggregateId, model, pmcId, name, location);
  });

  const newPagesWithAddedCount = Object.entries(vehicles.pages).map(([pageNumber, page]) => {
    const updatedRows = page
      .data!.filter((p) => p.vehicleAssignmentId === aggregateId && model.pmcIds.includes(p.parkingRight.pmc))
      .map((x) => ({
        ...x,
        vehicle: {
          ...x.vehicle,
          description: model.description,
        },
      }));

    const rowsToAdd = fakeRows.filter(
      (row) =>
        !updatedRows.some(
          (updateRow) =>
            updateRow.vehicleAssignmentId === row.vehicleAssignmentId &&
            row.parkingRight.pmc === updateRow.parkingRight.pmc
        ) &&
        rowMatchesQuery(
          row,
          page.query,
          (pmc) => getProductInfo(locatedParkingProducts, pmc).placeId
        )
    );

    const rowsToRemove = page.data!.filter(
      (x) => x.vehicleAssignmentId === aggregateId && !model.pmcIds.includes(x.parkingRight.pmc)
    );

    const data = [
      ...rowsToAdd,
      ...page.data!.flatMap((row) => {
        if (rowsToRemove.includes(row)) {
          return [];
        }

        const updatedRow = updatedRows.find((ur) => 
          ur.vehicleAssignmentId === row.vehicleAssignmentId && 
          ur.parkingRight.pmc === row.parkingRight.pmc);

        if (updatedRow) {
          return [updatedRow];
        }

        return [row];
      }),
    ];

    const newPage: typeof page = { ...page, data };
    return { pageNumber, page: newPage, rowsAddedCount: data.length - page.data!.length };
  });

  const newPages = Object.fromEntries(newPagesWithAddedCount.map((x) => [x.pageNumber, x.page]));

  const newTotalRecords =
    vehicles.totalRecords + newPagesWithAddedCount.reduce((acc, x) => acc + x.rowsAddedCount, 0);
  return { ...vehicles, totalRecords: newTotalRecords, pages: newPages };
}

function createFakeRow(
  aggregateId: string,
  assign: UpdateVehicleRequestModel,
  pmcId: number,
  name: string,
  location: string
): {
  vehicleAssignmentId: string;
  parkingRight: { id: string; pmc: number; name: string; location: string };
  assigned: Date;
  vehicle: { countryCode: string; identifier: string; description: string | undefined };
} {
  return {
    vehicleAssignmentId: aggregateId,
    parkingRight: {
      id: `fake pr id for pmc=${pmcId}`,
      pmc: pmcId,
      name,
      location,
    },
    assigned: new Date(),
    vehicle: {
      countryCode: assign.numberPlate.countryCode,
      identifier: assign.numberPlate.value,
      description: assign.description,
    },
  };
}

function reduceAssign(
  state: ParkingProductState,
  model: AssignVehicleRequestModel
): ParkingProductState {
  const newLocatedParkingProducts = incrementTotalAssignedCount(
    state.locatedParkingProducts.data!,
    Object.fromEntries(model.pmcIds.map((pmcId) => [pmcId, 1]))
  );

  const newVehicles = reduceVehicleState(
    state.vehicles,
    state.locatedParkingProducts.data!,
    model.aggregateId,
    model
  );

  return {
    ...state,
    locatedParkingProducts: {
      ...state.locatedParkingProducts,
      data: newLocatedParkingProducts,
    },
    vehicles: newVehicles,
  };
}

function getProductInfo(
  locations: LocatedParkingProduct[],
  pmcId: number
): { location: string; name: string; placeId: number } {
  for (const location of locations) {
    for (const product of location.parkingProducts) {
      if (product.pmcId === pmcId) {
        return {
          location: product.location,
          name: product.pmcName,
          placeId: location.placeId,
        };
      }
    }
  }

  throw new Error(`Can't find product info for pmc ${pmcId}`);
}

export function reduceVehicleAssignmentMutation(
  state: ParkingProductState,
  mutation: AssignedVehicleSuccessfulMutation
): ParkingProductState {
  switch (mutation.type) {
    case "update":
      return reduceUpdate(state, mutation.aggregateId, mutation.request);
    case "assign":
      return reduceAssign(state, mutation.request);
  }
}
