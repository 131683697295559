export const fleetManagerContextTypes = {
  SET_DELEGATION_TYPE: "context/SET_DELEGATION_TYPE",
  SET_ASSIGNMENT_TYPE: "context/SET_ASSIGNMENT_TYPE",
  SET_DRAG_AND_DROP_VIEW: "context/SET_DRAG_AND_DROP_VIEW",
  SET_NRPLATE_WARNING: "context/SET_NRPLATE_WARNING",
  SET_NRPLATE_ERROR: "context/SET_NRPLATE_ERROR",
  SET_SELECTED_REGISTRATION_ID: "context/SET_SELECTED_REGISTRATION_ID",
  SET_SELECTED_PARKINGRIGHT_IDS: "context/SET_SELECTED_PARKINGRIGHT_IDS",
  TOGGLE_SHOW_ADD_DELEGEE: "context/TOGGLE_SHOW_ADD_DELEGEE",
  TOGGLE_SHOW_ASSIGN_VEHICLE: "context/TOGGLE_SHOW_ASSIGN_VEHICLE",
  TOGGLE_SHOW_EDIT_VEHICLE_ASSIGNMENT: "context/TOGGLE_SHOW_EDIT_VEHICLE_ASSIGNMENT",
  TOGGLE_SHOW_EDIT_DELEGEE: "context/TOGGLE_SHOW_EDIT_DELEGEE",
  TOGGLE_SHOW_DELETE_DELEGEE: "context/SET_DELETE_DELEGEE",
  TOGGLE_SHOW_REVOKE_PARKINGRIGHTS: "context/TOGGLE_SHOW_REVOKE_PARKINGRIGHTS",
  TOGGLE_SHOW_REVOKE_VEHICLE_ASSIGNMENT: "context/TOGGLE_SHOW_VEHICLE_ASSIGNMENT",
  TOGGLE_SHOW_DELETE_VEHICLE_ASSIGNMENT: "context/TOGGLE_SHOW_DELETE_VEHICLE_ASSIGNMENT",
  TOGGLE_SHOW_BATCH_DELETE_DELEGATIONS: "context/TOGGLE_SHOW_BATCH_DELETE_DELEGATIONS",
  SET_PAGE_NUMBER: "context/SET_PAGE_NUMBER",
  SET_PAGE_SIZE: "context/SET_PAGE_SIZE",
  RESET_FLEET_MANAGER_CONTEXT: "context/RESET_FLEET_MANAGER_CONTEXT",
  TOGGLE_SHOW_DELEGEE_IMPORT_WARNING: "context/TOGGLE_SHOW_DELEGEE_IMPORT_WARNING"
};