export const VehicleAssignments = {
    KEY_SEPARATOR: '/',
};

export function createAssignedParkingRowKey(vehicleAssignmentId: string, pmcId: number) : string {
    return vehicleAssignmentId + VehicleAssignments.KEY_SEPARATOR + pmcId;
}

export function parseAssignedParkingRowKey(key: string): { vehicleAssignmentId: string, pmcId: number } {
    const vehicleAssignmentDetails = key.split(VehicleAssignments.KEY_SEPARATOR);

    return {
        vehicleAssignmentId: vehicleAssignmentDetails[0],
        pmcId: parseInt(vehicleAssignmentDetails[1])
    }
}

export function parseAssignedParkingRowKeyMultiplePmcs(key: string): { vehicleAssignmentId: string, pmcId: number }[] {
    const vehicleAssignmentDetails = key.split(VehicleAssignments.KEY_SEPARATOR);
    const vehicleAssignmentId = vehicleAssignmentDetails[0];
    const vehicleAssignmentPmcIds = vehicleAssignmentDetails.slice(1);

    const result: { vehicleAssignmentId: string, pmcId: number }[] = [];
    vehicleAssignmentPmcIds.forEach(pmcId => {
        result.push({
            vehicleAssignmentId: vehicleAssignmentId,
            pmcId: parseInt(pmcId)
        })
    });
    return result;
}

export function parseVehicleAssignmentId(key: string): string {
    const vehicleAssignmentDetails = key.split(VehicleAssignments.KEY_SEPARATOR);

    return vehicleAssignmentDetails[0];            
}