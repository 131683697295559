import React from "react";
import { SvgIcon } from "@material-ui/core";
import styled from "styled-components";
import { variables } from "../../theme/variables";

const { colors } = variables;

const Svg = styled(SvgIcon)`
  color: ${colors.grayishBlue.hex};
`;

const ForbiddenIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="14.531" height="14.531" viewBox="0 0 14.531 14.531">
    <path d="M7.5-12.891A7.266,7.266,0,0,0,.234-5.625,7.266,7.266,0,0,0,7.5,1.641a7.266,7.266,0,0,0,7.266-7.266A7.266,7.266,0,0,0,7.5-12.891Zm4.143,3.122a5.858,5.858,0,0,1,.468,7.76L3.883-10.236A5.858,5.858,0,0,1,11.643-9.768ZM3.357-1.482a5.858,5.858,0,0,1-.468-7.76l8.227,8.227A5.858,5.858,0,0,1,3.357-1.482Z" transform="translate(-0.234 12.891)"/>
  </Svg>
);

export default ForbiddenIcon;
