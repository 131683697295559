import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Warning from "./Warning";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import { RootReducer } from "../../store/reducers";
import { RevokeVehicleAssignmentsModel } from "../../models/vehicle-assignments/RevokeVehicleAssignmentsModel";
import { VehicleAssignmentProduct } from "../../models/vehicle-assignments/VehicleAssignmentProduct";
import { parseAssignedParkingRowKeyMultiplePmcs } from "../../constants/vehicleAssignment.constants";

interface IFormProps extends StateProps, DispatchProps {
    selectedVehicleAssignmentKeys: string[];
    onRefresh: (vehiclesToExclude?: VehicleAssignmentProduct[]) => void;
}

const RevokeVehicleAssignmentWarning = ({
    parkingProducts,
    revokeVehicleAssignmentsParkingRights,
    selectedVehicleAssignmentKeys,
    onRefresh
}: IFormProps) => {
    const { t } = useTranslation(["revokeVehicleAssignmentWarning", "globals"]);
    const { toggleRevokeVehicleAssignmentsWarning, setSelectedParkingRightIds } = useFleetManagerContext();

    const vehicleAssignmentProductCount = selectedVehicleAssignmentKeys.length;
    const warningText = t("warning", {count:vehicleAssignmentProductCount});
    const warningDetails = t("details", {count:vehicleAssignmentProductCount});

    const handleRevokeCancellation = () => {
        toggleRevokeVehicleAssignmentsWarning();
    }

    const handleSuccessfulRevoke = (model: VehicleAssignmentProduct[]) => {
        setSelectedParkingRightIds([]);
        toggleRevokeVehicleAssignmentsWarning();

        onRefresh(model);
    }

    const handleRevokeConfirmation = () => {
        const model: RevokeVehicleAssignmentsModel = {
            vehicleAssignmentProducts: selectedVehicleAssignmentKeys.map(vehicleAssignmentKey => 
                parseAssignedParkingRowKeyMultiplePmcs(vehicleAssignmentKey)).flat()
        };
        revokeVehicleAssignmentsParkingRights(model, () => handleSuccessfulRevoke(model.vehicleAssignmentProducts));
    }

    return (
        <Warning
        warning={warningText}
        details={warningDetails}
        onCancel={handleRevokeCancellation}
        onConfirm={handleRevokeConfirmation}
        disabled={false}
        confirmText={t("globals:revoke")}
        loading={parkingProducts.loading} />
    );
};

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts } = state;

  return {
    parkingProducts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    revokeVehicleAssignmentsParkingRights: (model: RevokeVehicleAssignmentsModel, callback: (vehiclesToExclude?: VehicleAssignmentProduct[]) => void) => 
        dispatch(parkingProductsActions.revokeVehicleAssignmentProducts(model, () => callback(model.vehicleAssignmentProducts)))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(RevokeVehicleAssignmentWarning);
