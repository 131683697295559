export const FmpFilters = {
  pageSize: "pageSize",
  pageNumber: "pageNumber",
  placeId: "placeId",
  name: "name",
  email: "email",
  invitationDate: "invitationDate",
  numberPlate: "numberPlate",
  description: "description",
  assignedDate: "assignedDate",
  statuses: "statuses",
  invitationsAccepted: "invitationsAccepted",
  products: "products",
  sortBy: "sortBy",
  orderDesc: "orderDesc",
};

export const VisitorBookingFilters = {
  name: "name",
  email: "email",
  facility: "physicalZoneUIds",
  products: "productGuids",
  creationDate: "creationDate",
  startDate: "startDate",
  endDate: "endDate",
  statuses: "statuses",
};
