import React, { createContext, useMemo, useReducer } from "react";
import IChildren from "../interfaces/IChildren";
import { fleetManagerContextTypes } from "./store/actions/fleetManagerContext.types";
import fleetManagerContextReducer, {
  AssignmentType,
  DelegationType,
  FleetManagerContextState,
  initialState,
} from "./store/reducers/fleetManagerContext.reducer";
import { AssignedVehicleRow } from "../containers/FleetManager/MyProducts/ParkingRightsDataTable/VehiclesTable";

export type FleetManagerContextType = {
  fleetManagerState: FleetManagerContextState;
  setSelectedRegistrationId: (registrationId: string) => void;
  setSelectedParkingRightIds: (parkingRightIds: string[]) => void;
  setDelegationType: (delegationType: DelegationType) => void;
  setAssignmentType: (assignmentType: AssignmentType) => void;
  setDragAndDropView: (show: boolean) => void;
  setNumberPlateWarning: (warning: string | undefined) => void;
  setNumberPlateError: (error: string | undefined) => void;
  toggleAddDelegee: () => void;
  toggleAssignVehicle: () => void;
  toggleEditVehicleAssignment: (vehicleAssignment?: AssignedVehicleRow) => void;
  toggleEditDelegee: (registrationId: string) => void;
  toggleDeleteDelegeeWarning: () => void;
  toggleRevokeParkingRightsWarning: () => void;
  toggleRevokeVehicleAssignmentsWarning: () => void;
  toggleDeleteVehicleAssignmentsWarning: (vehicleAssignment?: AssignedVehicleRow) => void;
  toggleBatchDeleteDelegationsWarning: () => void;
  toggleCloseDelegeeImportWarning: () => void;
  resetContext: () => void;
};

export const FleetManagerContext = createContext<FleetManagerContextType>(
  {} as FleetManagerContextType
);

const FleetManagerContextProvider = ({ children }: IChildren) => {
  const [fleetManagerState, dispatch] = useReducer(
    fleetManagerContextReducer,
    initialState
  );

  const setSelectedRegistrationId = (registrationId: string) =>
    dispatch({
      type: fleetManagerContextTypes.SET_SELECTED_REGISTRATION_ID,
      payload: registrationId,
    });

  const setSelectedParkingRightIds = (parkingRightIds: string[]) =>
    dispatch({
      type: fleetManagerContextTypes.SET_SELECTED_PARKINGRIGHT_IDS,
      payload: parkingRightIds,
    });

  const setDelegationType = (delegationType: DelegationType) =>
    dispatch({
      type: fleetManagerContextTypes.SET_DELEGATION_TYPE,
      payload: delegationType,
    });

  const setAssignmentType = (assignmentType: AssignmentType) =>
    dispatch({
      type: fleetManagerContextTypes.SET_ASSIGNMENT_TYPE,
      payload: assignmentType,
    });

  const setDragAndDropView = (show: boolean) =>
    dispatch({
      type: fleetManagerContextTypes.SET_DRAG_AND_DROP_VIEW,
      payload: show,
    });

  const setNumberPlateWarning = (warning: string | undefined) =>
    dispatch({
      type: fleetManagerContextTypes.SET_NRPLATE_WARNING,
      payload: warning,
    });

  const setNumberPlateError = (error: string | undefined) =>
    dispatch({
      type: fleetManagerContextTypes.SET_NRPLATE_ERROR,
      payload: error,
    });

  const toggleAddDelegee = () =>
    dispatch({
      type: fleetManagerContextTypes.TOGGLE_SHOW_ADD_DELEGEE,
    });

  const toggleAssignVehicle = () =>
    dispatch({
      type: fleetManagerContextTypes.TOGGLE_SHOW_ASSIGN_VEHICLE,
    });

  const toggleEditVehicleAssignment = (vehicleAssignment?: AssignedVehicleRow) =>
    dispatch({
      type: fleetManagerContextTypes.TOGGLE_SHOW_EDIT_VEHICLE_ASSIGNMENT,
      payload: vehicleAssignment,
    });

  const toggleEditDelegee = (registrationId: string) =>
    dispatch({
      type: fleetManagerContextTypes.TOGGLE_SHOW_EDIT_DELEGEE,
      payload: registrationId,
    });

  const toggleDeleteDelegeeWarning = () =>
    dispatch({
      type: fleetManagerContextTypes.TOGGLE_SHOW_DELETE_DELEGEE,
    });

  const toggleRevokeParkingRightsWarning = () =>
    dispatch({
      type: fleetManagerContextTypes.TOGGLE_SHOW_REVOKE_PARKINGRIGHTS,
    });

  const toggleRevokeVehicleAssignmentsWarning = () =>
    dispatch({
      type: fleetManagerContextTypes.TOGGLE_SHOW_REVOKE_VEHICLE_ASSIGNMENT,
    });

   const toggleDeleteVehicleAssignmentsWarning = (vehicleAssignment?: AssignedVehicleRow) =>
        dispatch({
            type: fleetManagerContextTypes.TOGGLE_SHOW_DELETE_VEHICLE_ASSIGNMENT,
            payload: vehicleAssignment
        });

  const toggleBatchDeleteDelegationsWarning = () =>
    dispatch({
      type: fleetManagerContextTypes.TOGGLE_SHOW_BATCH_DELETE_DELEGATIONS,
    });

  const toggleCloseDelegeeImportWarning = () =>
    dispatch({
      type: fleetManagerContextTypes.TOGGLE_SHOW_DELEGEE_IMPORT_WARNING,
    });

  const resetContext = () =>
    dispatch({
      type: fleetManagerContextTypes.RESET_FLEET_MANAGER_CONTEXT,
    });

  const store: FleetManagerContextType = {
    fleetManagerState,
    setSelectedRegistrationId,
    setSelectedParkingRightIds,
    setDelegationType,
    setAssignmentType,
    setNumberPlateWarning,
    setNumberPlateError,
    setDragAndDropView,
    toggleAddDelegee,
    toggleAssignVehicle,
    toggleEditVehicleAssignment,
    toggleEditDelegee,
    resetContext,
    toggleDeleteDelegeeWarning,
    toggleRevokeParkingRightsWarning,
    toggleRevokeVehicleAssignmentsWarning,
    toggleDeleteVehicleAssignmentsWarning,
    toggleBatchDeleteDelegationsWarning,
    toggleCloseDelegeeImportWarning,
  };

  const memoStore = useMemo(
    () => ({
      store,
    }),
    [store]
  );

  return (
    <FleetManagerContext.Provider value={memoStore.store}>
      {children}
    </FleetManagerContext.Provider>
  );
};

export default FleetManagerContextProvider;
