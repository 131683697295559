import React from "react";
import { Checkbox, CheckboxProps } from "@material-ui/core";
import styled from "styled-components";
import { Check } from "@material-ui/icons";
import { variables } from "../../theme/variables";

const { colors } = variables;

const StyledCheck = styled(Check)`
  width: 0.6em;
  height: 0.6em;
  border: 1px solid ${colors.veryLightGraySecondary};
  border-radius: 4px;
  color: transparent;
  background-color: ${colors.white};
  &:hover {
    background: none;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

const Checked = styled(StyledCheck)`
  color: ${colors.red.hex};
`;

const FlexSpan = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
`;

const EllipsedSpan = styled.span`
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 8px;
`;

export interface ICheckboxProps {
  label?: string;
  className?: string;
  checkboxProps?: CheckboxProps;
}

const CustomCheckbox = ({
  label,
  checkboxProps,
  className,
}: ICheckboxProps) => (
  <FlexSpan className={className}>
    <StyledCheckbox
      disableRipple
      color="default"
      icon={<StyledCheck />}
      checkedIcon={<Checked />}
      {...checkboxProps}
    />
    {label && <EllipsedSpan>{label}</EllipsedSpan>}
  </FlexSpan>
);

export default CustomCheckbox;
